import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard'; // Dashboard existente para o administrador
import Relatorios from './components/Relatorios'; // Página de relatórios para o administrador
import Usuarios from './components/Usuarios'; // Página de gerenciamento de usuários para o administrador
import UsuarioDashboard from './components/UsuarioDashboard'; // Dashboard novo para o usuário
import Motoristas from './components/Motoristas'; // Página de motoristas para o usuário e/ou admin
import ConferenciaPlacas from './components/ConferenciaPlacas'; // Página de conferência de placas para o usuário
import Login from './components/Login'; // Tela de login
import Status from './components/Status'; // Página Status.js (nova página principal)
import { Box } from '@mui/material';

function App() {
  const [userProfile, setUserProfile] = useState(null); // Estado para armazenar o perfil do usuário

  return (
    <Router>
      <Box sx={{ display: 'flex', width: '100%' }}> {/* Garantindo que o layout ocupe 100% da largura */}
        <Box
          sx={{
            flexGrow: 1,
            p: 3, // Espaçamento interno
            backgroundColor: '#f5f5f5',
            minHeight: '100vh',
            width: '100%', // O Box agora ocupa 100% da largura
          }}
        >
          <Routes>
            {/* Página principal agora é a Status */}
            <Route path="/" element={<Status />} />

            {/* Rota para /admin leva ao login */}
            <Route path="/admin" element={<Login setUserProfile={setUserProfile} />} />

            {/* Página de Login em outra rota, se necessário */}
            <Route path="/login" element={<Login setUserProfile={setUserProfile} />} />

            {/* Dashboard do Administrador */}
            <Route path="/admin-dashboard" element={<Dashboard />} />

            {/* Página de Relatórios para o Administrador */}
            <Route path="/relatorios" element={<Relatorios />} />

            {/* Página de Gerenciamento de Usuários para o Administrador */}
            <Route path="/usuarios" element={<Usuarios />} />

            {/* Dashboard do Usuário */}
            <Route path="/usuario-dashboard" element={<UsuarioDashboard />} />

            {/* Página de Motoristas para o Usuário */}
            <Route path="/motoristas" element={<Motoristas />} />

            {/* Página de Conferência de Placas para o Usuário */}
            <Route path="/conferencia-placas" element={<ConferenciaPlacas />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
