import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Status.css"; // Estilos do CSS

const App = () => {
  const [results, setResults] = useState([]); // Resultados da API
  const [error, setError] = useState(null); // Estado de erro

  // Função para carregar os dados da API inicialmente e a cada 5 segundos
  useEffect(() => {
    fetchResults(); // Carrega os dados inicialmente
    const fetchInterval = setInterval(fetchResults, 5000); // Atualiza a cada 5 segundos
    return () => clearInterval(fetchInterval); // Limpa o intervalo ao desmontar o componente
  }, []);

  // Função para carregar os resultados da API
  const fetchResults = async () => {
    try {
      setError(null); // Limpar estado de erro
      const response = await axios.get("https://coopergraos.com.br/api/get_status.php");
      
      // Verifica se o retorno é um array, caso contrário, trata como um array vazio
      if (Array.isArray(response.data)) {
        setResults(response.data); // Atualiza os resultados com os dados da API
      } else {
        setResults([]); // Caso não seja um array, define como vazio
        console.error("A resposta da API não é um array:", response.data);
      }
    } catch (error) {
      setError("Erro ao buscar os dados. Tente novamente mais tarde.");
      console.error("Erro ao buscar os dados:", error);
    }
  };

  return (
    <div className="App">
      <header className="header">
        <div className="container">
          <div id="logo">
            <img
              src="https://athix.com.br/img/bg_cooperfibra.png"
              id="icon"
              alt="User Icon"
            />
          </div>
          <br />
          <div className="nav">
            FIQUE ATENTO AS INFORMAÇÕES DO SEU CARREGAMENTO VERIFICANDO A TELA
            REGULARMENTE
          </div>
        </div>
      </header>

      <div className="container-fluid">
        {/* Exibe uma mensagem de erro, se houver */}
        {error ? (
          <div className="error">{error}</div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>PLACA</th>
                <th>MOTORISTA</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {/* Verifica se 'results' é um array antes de usar .map() */}
              {results.length > 0 ? (
                results.map((item, index) => (
                  <tr key={index} className={item.status?.toLowerCase()}>
                    <td>{item.placa}</td>
                    <td>{item.nome_moto}</td>
                    <td>{item.status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">Nenhum dado disponível.</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default App;
