import React, { useState } from 'react';
import {
  Container, Grid, TextField, Button, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, Modal, Box, TablePagination
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';

function Usuarios() {
  const [users, setUsers] = useState([
    { id: '1', username: 'admin', profile: 'Administrador', password: 'adminpass' },
    { id: '2', username: 'user1', profile: 'Usuário', password: 'userpass' },
  ]);

  const [newUser, setNewUser] = useState({ username: '', profile: 'Usuário', password: '' });
  const [editUser, setEditUser] = useState(null);
  const [viewUser, setViewUser] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Função para mudar as páginas
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Função para mudar as linhas por página
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUsers([...users, { ...newUser, id: Date.now().toString() }]);
    setNewUser({ username: '', profile: 'Usuário', password: '' });
  };

  // Abrir o modal de editar
  const handleEditClick = (user) => {
    setEditUser(user);
    setOpenEditModal(true);
  };

  // Abrir o modal de visualizar
  const handleViewClick = (user) => {
    setViewUser(user);
    setOpenViewModal(true);
  };

  const handleSaveEdit = () => {
    setUsers(users.map((u) => (u.id === editUser.id ? editUser : u)));
    setOpenEditModal(false);
    setEditUser(null);
  };

  const handleDeleteClick = (id) => {
    setUsers(users.filter((u) => u.id !== id));
  };

  return (
    <Container>
      <h2 style={{ textAlign: 'center', color: '#1976d2' }}>Gerenciamento de Usuários</h2>

      {/* Formulário para adicionar usuários */}
      <Paper sx={{ p: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Nome de Usuário"
              name="username"
              fullWidth
              value={newUser.username}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Perfil"
              name="profile"
              fullWidth
              value={newUser.profile}
              onChange={handleChange}
              required
            >
              <MenuItem value="Administrador">Administrador</MenuItem>
              <MenuItem value="Usuário">Usuário</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Senha"
              name="password"
              type="password"
              fullWidth
              value={newUser.password}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Criar Usuário
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Tabela de usuários */}
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Table sx={{ minWidth: 650 }} aria-label="Usuários">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome de Usuário</TableCell>
              <TableCell>Perfil</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.profile}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleViewClick(user)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleEditClick(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteClick(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={users.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 50]} // Opções de linhas por página
        />
      </Paper>

      {/* Modal de Visualização */}
      <Modal open={openViewModal} onClose={() => setOpenViewModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>Visualizar Usuário</h2>
          {viewUser && (
            <>
              <p>Nome de Usuário: {viewUser.username}</p>
              <p>Perfil: {viewUser.profile}</p>
              <p>Senha: {viewUser.password}</p>
            </>
          )}
        </Box>
      </Modal>

      {/* Modal de Edição */}
      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2>Editar Usuário</h2>
          {editUser && (
            <>
              <TextField
                label="Nome de Usuário"
                name="username"
                fullWidth
                value={editUser.username}
                onChange={(e) => setEditUser({ ...editUser, [e.target.name]: e.target.value })}
                sx={{ mb: 2 }}
              />
              <TextField
                select
                label="Perfil"
                name="profile"
                fullWidth
                value={editUser.profile}
                onChange={(e) => setEditUser({ ...editUser, [e.target.name]: e.target.value })}
                sx={{ mb: 2 }}
              >
                <MenuItem value="Administrador">Administrador</MenuItem>
                <MenuItem value="Usuário">Usuário</MenuItem>
              </TextField>
              <TextField
                label="Senha"
                name="password"
                type="password"
                fullWidth
                value={editUser.password}
                onChange={(e) => setEditUser({ ...editUser, [e.target.name]: e.target.value })}
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" onClick={handleSaveEdit}>
                Salvar
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
}

export default Usuarios;
