import React, { useState } from 'react';
import axios from 'axios';
import './Login.css'; // Importando o CSS para estilizar a página

const Login = ({ setUserProfile }) => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Dados a serem enviados para o PHP (API)
    const loginData = {
      email: email, // Envia o e-mail
      senha: senha, // Envia a senha (o PHP fará o MD5)
    };

    try {
      const response = await axios.post('https://coopergraos.com.br/api/valida.php', loginData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true, // Inclui cookies e sessões
      });

      if (response.data.success) {
        setUserProfile({
          id: response.data.id,
          nome: response.data.nome,
          nivelAcesso: response.data.nivelAcesso,
        });

        // Redireciona para o dashboard correto
        if (response.data.nivelAcesso === 1) {
          window.location.href = '/admin-dashboard';
        } else if (response.data.nivelAcesso === 2) {
          window.location.href = '/colaborador';
        } else {
          window.location.href = '/cliente';
        }
      } else {
        setErrorMessage('Usuário ou senha inválidos');
      }
    } catch (error) {
      console.error('Erro ao tentar fazer login:', error);
      setErrorMessage('Erro ao conectar com o servidor');
    }
  };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <div className="fadeIn first">
          <img src="https://athix.com.br/img/bg_cooperfibra.png" id="icon" alt="User Icon" />
        </div>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="fadeIn second"
            placeholder="Usuário"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            className="fadeIn third"
            placeholder="Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
          />
          <input type="submit" className="fadeIn fourth" value="Entrar" />
        </form>

        {errorMessage && <p className="text-center text-danger">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Login;
