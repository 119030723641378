import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  TextField,
  MenuItem,
  Container
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const drawerWidth = 240;

const useStyles = {
  appBar: {
    backgroundColor: '#4285F4', // Azul padrão Google
    height: '64px', // Altura ajustada do AppBar
    display: 'flex',
    justifyContent: 'center',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px', // Espaçamento interno para o conteúdo do header
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#ffffff', // Fundo branco neutro
  },
  logoContainer: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImage: {
    width: '100%', // Ajuste para que a logo ocupe o máximo de largura possível
    maxWidth: '180px', // Definir um limite para o tamanho da logo
  },
  content: {
    flexGrow: 1,
    padding: '24px', // Ajuste de espaçamento interno do conteúdo
    marginLeft: drawerWidth,
    marginTop: '80px', // Distância do conteúdo para o header
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    p: 4,
    borderRadius: '8px',
  },
  table: {
    marginTop: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  modalTitle: {
    marginBottom: '16px',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  inputField: {
    marginBottom: '16px',
  },
  listItemText: {
    color: '#333', // Cor escura para o texto no menu lateral (seguindo estilo Google)
  },
};

const Dashboard = () => {
  const [statusList, setStatusList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [modalType, setModalType] = useState(''); // 'edit' ou 'delete' ou 'add'
  const [nomeMotorista, setNomeMotorista] = useState('');
  const [placa, setPlaca] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Carregar dados da API
  useEffect(() => {
    fetchStatusList();
  }, []);

  const fetchStatusList = async () => {
    try {
      const response = await axios.get('https://coopergraos.com.br/api/processa.php?action=fetch');
      setStatusList(response.data);
    } catch (error) {
      console.error('Erro ao buscar lista de status:', error);
    }
  };

  // Abrir o modal
  const handleOpenModal = (type, status = null) => {
    setModalType(type);
    setSelectedStatus(status);
    setNewStatus(status?.status || '');
    setNomeMotorista(status?.nome_moto || '');
    setPlaca(status?.placa || '');
    setOpenModal(true);
  };

  // Fechar o modal e limpar os campos
  const handleCloseModal = () => {
    setOpenModal(false);
    resetForm(); // Limpar os estados do formulário
  };

  // Resetar os campos do formulário
  const resetForm = () => {
    setSelectedStatus(null);
    setNewStatus('');
    setNomeMotorista('');
    setPlaca('');
    setErrorMessage('');
  };

  // Atualizar ou adicionar status
  const handleSubmit = async () => {
    // Verifica se todos os campos estão preenchidos antes de enviar
    if (!nomeMotorista || !placa || !newStatus) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }

    try {
      let url = 'https://coopergraos.com.br/api/processa.php';
      let payload = {};

      if (modalType === 'edit') {
        payload = {
          id: selectedStatus.id,
          nome_moto: nomeMotorista,
          placa,
          status: newStatus,
        };
        url += '?action=update';
      } else if (modalType === 'add') {
        payload = {
          nome_moto: nomeMotorista,
          placa,
          status: newStatus,
        };
        url += '?action=add';
      }

      await axios.post(url, payload);
      setErrorMessage('');
      fetchStatusList(); // Atualizar a lista após a ação
      handleCloseModal();
    } catch (error) {
      setErrorMessage('Erro ao enviar os dados.');
      console.error('Erro:', error);
    }
  };

  // Remover status
  const handleDeleteStatus = async () => {
    try {
      await axios.post('https://coopergraos.com.br/api/processa.php?action=delete', {
        id: selectedStatus.id,
      });
      setErrorMessage('');
      fetchStatusList(); // Atualizar a lista após remover
      handleCloseModal();
    } catch (error) {
      setErrorMessage('Erro ao remover status.');
      console.error('Erro ao remover status:', error);
    }
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" sx={useStyles.appBar}>
        <Toolbar sx={useStyles.toolbar}>
          <Typography variant="h6" noWrap>
            Painel Admin
          </Typography>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={useStyles.drawer}
        PaperProps={{
          sx: useStyles.drawerPaper,
        }}
      >
        <div style={useStyles.logoContainer}>
          {/* Adicionando a logo no topo do menu */}
          <img src="https://athix.com.br/img/bg_cooperfibra.png" alt="Logo" style={useStyles.logoImage} />
        </div>

        <List>
          <ListItem button>
            <ListItemText primary="Dashboard" sx={useStyles.listItemText} />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Painel de Status" sx={useStyles.listItemText} />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Conferir Placas" sx={useStyles.listItemText} />
          </ListItem>
        </List>
      </Drawer>

      <main style={useStyles.content}>
        <Container>
          {/* Botão de adicionar acima da tabela */}
          <div style={useStyles.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={() => handleOpenModal('add')}
              sx={{ textTransform: 'none', backgroundColor: '#34a853' }} // Verde Google
            >
              Adicionar Motorista
            </Button>
          </div>

          <TableContainer component={Paper} sx={useStyles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Placa</TableCell>
                  <TableCell>Nome do Motorista</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusList.map((status) => (
                  <TableRow key={status.id}>
                    <TableCell>{status.id}</TableCell>
                    <TableCell>{status.placa}</TableCell>
                    <TableCell>{status.nome_moto}</TableCell>
                    <TableCell>{status.status}</TableCell>
                    <TableCell>
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleOpenModal('edit', status)}
                        sx={{ textTransform: 'none' }} // Remover uppercase nos botões
                      >
                        Editar
                      </Button>
                      <Button
                        variant="text"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleOpenModal('delete', status)}
                        sx={{ textTransform: 'none', marginLeft: '10px' }}
                      >
                        Remover
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={useStyles.modalBox}>
            <Typography variant="h6" sx={useStyles.modalTitle}>
              {modalType === 'edit' ? 'Editar Motorista' : modalType === 'add' ? 'Adicionar Motorista' : 'Remover Motorista'}
            </Typography>

            {modalType === 'edit' || modalType === 'add' ? (
              <>
                <TextField
                  label="Nome do Motorista"
                  value={nomeMotorista}
                  onChange={(e) => setNomeMotorista(e.target.value)}
                  fullWidth
                  sx={useStyles.inputField}
                />
                <TextField
                  label="Placa"
                  value={placa}
                  onChange={(e) => setPlaca(e.target.value)}
                  fullWidth
                  sx={useStyles.inputField}
                />
                <TextField
                  label="Status"
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  fullWidth
                  select
                  sx={useStyles.inputField}
                >
                  <MenuItem value="LIBERADO">LIBERADO</MenuItem>
                  <MenuItem value="AGUARDANDO">AGUARDANDO</MenuItem>
                  <MenuItem value="COMPARECER NO ESCRITORIO">
                    COMPARECER NO ESCRITORIO
                  </MenuItem>
                </TextField>
              </>
            ) : modalType === 'delete' ? (
              <Typography>
                Deseja remover o motorista <strong>{selectedStatus?.nome_moto}</strong>?
              </Typography>
            ) : null}

            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            <Box mt={2}>
              {modalType === 'edit' || modalType === 'add' ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ textTransform: 'none' }}
                >
                  {modalType === 'edit' ? 'Atualizar' : 'Adicionar'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDeleteStatus}
                  sx={{ textTransform: 'none' }}
                >
                  Remover
                </Button>
              )}
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseModal}
                sx={{ marginLeft: '10px', textTransform: 'none' }}
              >
                Cancelar
              </Button>
            </Box>
          </Box>
        </Modal>
      </main>
    </>
  );
};

export default Dashboard;
